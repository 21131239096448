export default {
  "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ПРИВЕТ i18n !!"])},
  "nav": {
    "Bussiness": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Бизнесу"])},
    "Individuals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Частным лицам"])},
    "ServicesandPayments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сервисы и платежи"])},
    "ServicesandPayments1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сервисы"])},
    "AboutUs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["О компании"])},
    "Vacancies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вакансии"])},
    "Contacts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Контакты"])},
    "Address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Адреса"])}
  },
  "bussiness": {
    "transport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Страхование транспорта"])},
    "property": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Страхование имущества"])},
    "cargo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Страхование грузов"])},
    "response": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Страхование ответственности"])},
    "health": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Страхование здоровья"])},
    "temporary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вменённое страхование"])}
  },
  "individual": {
    "auto": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Автострахование"])},
    "health": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Страхование здоровья"])},
    "property": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Страхование имущества"])},
    "tourist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Страхование туристов"])},
    "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Обязательные виды страхования"])},
    "bussiness": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Страхование бизнеса"])}
  },
  "service": {
    "buy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Купить полис"])},
    "check": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Проверить полис"])},
    "payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Оплата договоров страхования"])},
    "calc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Онлайн-калькулятор"])},
    "online_settlement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Онлайн урегулирование"])}
  },
  "about": {
    "AboutUs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["О нас"])},
    "doc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Документы"])},
    "press": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пресс-центр"])},
    "compl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Комплаенс"])},
    "vac": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вакансии"])},
    "event": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мероприятия"])},
    "ac": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Акционерам"])},
    "dig": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Цифровые проекты"])},
    "quest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FAQ"])},
    "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Адреса"])}
  },
  "buttons": {
    "getPresentation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Получить презентацию компании"])},
    "moreBtn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Подробнее"])},
    "moreBtn1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Подробнее на"])},
    "apply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Оформить страховку"])},
    "news": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Новости компании"])},
    "allNews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Все главные новости"])},
    "faq": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Часто задаваемые вопросы"])},
    "vac": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вакансии"])},
    "vacDesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вакансии нашей компании"])},
    "dig": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Digital-продукты и полезные приложения от холдинга Сentras"])},
    "show": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Показать еще"])},
    "based": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мы находимся"])},
    "call": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Заказать обратный звонок"])},
    "lmore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Узнать подробнее"])},
    "kasko": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["КАСКО"])},
    "limit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Автолимит с франшизой"])},
    "sakta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["САКТА"])},
    "pledged": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Авто в залоге"])},
    "send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отправить"])},
    "polis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Оформить полис"])},
    "insurance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Страхуем здоровье, имущество и транспорт с 1997 года"])},
    "showMap": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Показать на карте"])},
    "ans": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ответы на частые вопросы"])},
    "history": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["История"])},
    "rating": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["РЕЙТИНГИ И Участие в ассоциациях"])},
    "form": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Заполните форму"])},
    "public": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Авторизованнное обращение"])},
    "anon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Анонимное обращение"])},
    "offer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Хочу работать у вас"])},
    "speaker": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Стать спикером"])},
    "sponsor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Стать спонсором"])},
    "hold1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Решения акционеров"])},
    "hold2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Реестр держателей акций"])},
    "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата"])},
    "dec": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Решение"])},
    "basic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Основные"])},
    "pay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Оплата"])},
    "decor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ОФОРМЛЕНИЕ"])},
    "other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Другие вопросы"])},
    "down": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Скачать логотип"])},
    "go": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Перейти"])},
    "get": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Получить подробную информацию"])}
  },
  "offer": {
    "security": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Политика информационной безопасности"])}
  },
  "documents": {
    "lis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Лицензия"])},
    "fin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Финансовая отчетность"])},
    "reestr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Реестр страховых агентов"])},
    "reg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Справка о гос. регистрации"])},
    "tar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Страховые тарифы"])},
    "rule": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Правила страхования"])},
    "rec": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Реквизиты"])},
    "ruleRu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Правила внутреннего контроля за инсайдерской информацией на русском языке"])},
    "ruleKz": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Правила внутреннего контроля за инсайдерской информацией на казахском языке"])},
    "rule1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Действующие правила по обязательным видам страхования"])},
    "rule2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Действующие правила по добровольным видам страхования"])},
    "rule3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Утратившие силу правила по обязательным и добровольным видам страхования"])},
    "comissionFees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Комиссионные вознаграждения"])}
  },
  "par": {
    "bus1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Будьте спокойны за ваш бизнес с"])},
    "bus2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["индивидуальным"])},
    "bus3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["решением по защите от непредвиденных рисков"])},
    "dev1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сайт разработан компанией"])},
    "dev2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Studio Nomad"])},
    "less": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Меньше"])},
    "reviewMore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Читать отзыв полностью"])},
    "more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Подробнее"])},
    "choose": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выбрать"])},
    "chooseFile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Файл выбран"])}
  },
  "modal": {
    "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Экономьте вместе с нами"])},
    "individDisc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Заполните форму и получите индивидуальную скидку на наши продукты."])},
    "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выберите вид страхования"])},
    "res": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Прикрепить резюме"])},
    "we": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мы рады вам помочь"])},
    "spec": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Наши специалисты помогут вам решить любой вопрос. Просто выберите интересующие Вас продукты и закажите обратный звонок."])},
    "more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Хотите узнать о наших продуктах подробнее?"])},
    "present": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мы в течение 5 минут направим презентацию о компании и наших продуктах. Просто оставьте Ваши данные."])},
    "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Здравствуйте! Я рад Вам помочь!"])},
    "call": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пожалуйста, выберите интересующие Вас продукты и заполните данные. Мы свяжемся с Вами в ближайшее время."])},
    "check1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Соглашаюсь с"])},
    "check2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["политикой обработки персональных данных"])},
    "rule1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нажимая на кнопку, вы соглашаетесь с условиями"])},
    "rule2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["обработки персональных данных"])},
    "city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выберите город"])},
    "vacancy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Откликнуться на вакансию"])}
  },
  "placeholder": {
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Имя*"])},
    "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Номер телефона*"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mail*"])},
    "company": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Название компании*"])},
    "com": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Комментарии (необязательно)"])},
    "choose": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выберите один из вариантов"])},
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Текст обращения..."])},
    "req": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Обязательное поле!"])},
    "reqEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Неправильно введённый email!"])}
  },
  "notification": {
    "req": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Заполните обязательные поля, пожалуйста"])},
    "dontagree": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вы не согласились с политикой обработки персональных данных"])},
    "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ваша форма успешно отправлена!"])},
    "reqProduct": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Обязательные поля не заполнены или вид страхования не был найден!"])},
    "successVacancy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Успешно создан! Мы свяжемся с вами в ближайшее время."])},
    "compl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Спасибо, мы обязательно учтем ваши замечания!"])},
    "send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ваша форма отправлена!"])}
  },
  "product": {
    "med": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Медицина"])},
    "kasko": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Автокаско"])},
    "cargo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Грузы"])},
    "property": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Имущество"])},
    "gpo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Гражданско-правовая ответственность перед третьими лицами"])},
    "law": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Обязательное страхование юр. лиц"])},
    "ns": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Обязательное страхование работников от нс"])},
    "other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Другое"])},
    "gpoText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Защитим ваши финансовые интересы в случае наступления гражданской ответственности"])}
  },
  "medicineRespPul": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Страхование профессиональной ответственности медицинских работников"])},
    "p_first": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Надежная финансовая защита ВАШИХ интересов при наступлении страхового случая"])},
    "p_two": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Страховая Компания «Сентрас Иншуранс» является членом Единого страхового пула по страхованию профессиональной ответственности медицинских работников"])},
    "p_three": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Для заключения договора страхования необходимо пройти по ссылке на интернет-ресурс пула"])},
    "button_instruction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Инструкции"])}
  },
  "medicinePulInstructions": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Инструкции"])},
    "terminationContract": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Расторжение договора"])},
    "CreatingContract": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Создание договора"])},
    "CreatingAdditionalAgreement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Создание дополнительного соглашения"])},
    "CreatingPersonalAccountForIE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Создание личного кабинета для ИП"])},
    "CreatingPersonalAccountLegalEntity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Создание личного кабинета для юр. лица"])}
  }
}