<template>
  <div>
    <head-individ />
    <another-site />
    <div class="container">
      <div class="autoIndivid">
        <div class="autoIndivid-left">
          <p class="autoIndivid-left__title" v-html="autoInd[0].title"></p>
          <p class="autoIndivid-left__sub" v-html="autoInd[0].desc"></p>
          <div class="autoIndivid-left__type">
            <div class="autoIndivid-left__type__par">
              <p class="title" v-html="autoInd[0].sub_type[0].title"></p>
              <p class="sub" v-html="autoInd[0].sub_type[0].desc"></p>
            </div>
            <div class="autoIndivid-left__type__par">
              <p class="title" v-html="autoInd[0].sub_type[1].title"></p>
              <p class="sub" v-html="autoInd[0].sub_type[1].desc"></p>
            </div>
            <div class="autoIndivid-left__type__par">
              <p class="title" v-html="autoInd[0].sub_type[2].title"></p>
              <p class="sub" v-html="autoInd[0].sub_type[2].desc"></p>
            </div>
          </div>
          <div class="autoIndivid-left__btn white_hover__btn">
            <a :href="$i18n.locale === 'en' ? '/address' : autoInd[0].link">
              <button>
                {{ $i18n.locale === 'en' ? 'MORE INFORMATION' : $t('buttons.moreBtn1')+" kupipolis.kz" }}
              </button>
            </a>
          </div>

        </div>
        <div class="autoIndivid-left__btn__mobile white_hover__btn">
          <a :href="$i18n.locale === 'en' ? '/address' : autoInd[0].link">
              <button>
                {{ $i18n.locale === 'en' ? 'MORE INFORMATION' : $t('buttons.moreBtn1')+" kupipolis.kz" }}
              </button>
            </a>
        </div>
        <div class="autoIndivid-right">
          <img :src="cdn + autoInd[0].sub_type[0].icon" alt="" />
        </div>
      </div>
      <div class="autoIndivid">
        <div class="autoIndivid-left">
          <p class="autoIndivid-left__title" v-html="autoInd[1].title"></p>
          <p class="autoIndivid-left__sub" v-html="autoInd[1].desc"></p>
          <div class="autoIndivid-left__type">
            <div class="autoIndivid-left__type__par">
              <p class="title" v-html="autoInd[1].sub_type[0].title"></p>
              <p class="sub" v-html="autoInd[1].sub_type[0].desc"></p>
            </div>
            <div class="autoIndivid-left__type__par">
              <p class="title" v-html="autoInd[1].sub_type[1].title"></p>
              <p class="sub" v-html="autoInd[1].sub_type[1].desc"></p>
            </div>
            <div class="autoIndivid-left__type__par">
              <p class="title" v-html="autoInd[1].sub_type[2].title"></p>
              <p class="sub" v-html="autoInd[1].sub_type[2].desc"></p>
            </div>
          </div>
          <div class="autoIndivid-left__btn white_hover__btn">
            <a :href="$i18n.locale === 'en' ? '/address': autoInd[1].link">
              <button>
                {{ $i18n.locale === 'en' ? 'MORE INFORMATION' : $t('buttons.moreBtn1')+" kupipolis.kz" }}
              </button>
            </a>
          </div>
        </div>
        <div class="autoIndivid-left__btn__mobile white_hover__btn">
          <a :href="$i18n.locale === 'en' ? '/address' : autoInd[1].link">
              <button>
                {{ $i18n.locale === 'en' ? 'MORE INFORMATION' : $t('buttons.moreBtn1')+" kupipolis.kz" }}
              </button>
            </a>
        </div>
        <div class="autoIndivid-right">
          <img :src="cdn + autoInd[1].icon" alt="" />
        </div>
      </div>
      <div class="autoIndivid">
        <div class="autoIndivid-left">
          <p class="autoIndivid-left__title" v-html="autoInd[2].title"></p>
          <p class="autoIndivid-left__sub" v-html="autoInd[2].desc"></p>
          <div class="autoIndivid-left__type">
            <div class="autoIndivid-left__type__par">
              <p class="title" v-html="autoInd[2].sub_type[0].title"></p>
              <p class="sub" v-html="autoInd[2].sub_type[0].desc"></p>
            </div>
            <div class="autoIndivid-left__type__par">
              <p class="title" v-html="autoInd[0].sub_type[3].title"></p>
              <p class="sub" v-html="autoInd[0].sub_type[3].desc"></p>
            </div>
            <div class="autoIndivid-left__type__par">
              <p class="title" v-html="autoInd[2].sub_type[1].title"></p>
              <p class="sub" v-html="autoInd[2].sub_type[1].desc"></p>
            </div>
          </div>
          <div class="autoIndivid-left__btn white_hover__btn">
            <a :href="$i18n.locale === 'en' ? '/address' : autoInd[2].link">
              <button>
                {{ $i18n.locale === 'en' ? 'MORE INFORMATION' : $t('buttons.moreBtn1')+" kupipolis.kz" }}
              </button>
            </a>
          </div>
        </div>
        <div class="autoIndivid-left__btn__mobile white_hover__btn">
          <a :href="$i18n.locale === 'en' ? '/address' : autoInd[2].link">
              <button>
                {{ $i18n.locale === 'en' ? 'MORE INFORMATION' : $t('buttons.moreBtn1')+" kupipolis.kz" }}
              </button>
            </a>
        </div>
        <div class="autoIndivid-right">
          <img :src="cdn + autoInd[2].icon" alt="" />
        </div>
      </div>
      <div class="autoIndivid">
        <div class="autoIndivid-left">
          <p class="autoIndivid-left__title" v-html="autoInd[3].title"></p>
          <p class="autoIndivid-left__sub" v-html="autoInd[3].desc"></p>
          <div class="autoIndivid-left__type">
            <div class="autoIndivid-left__type__par">
              <p class="title" v-html="autoInd[4].sub_type[1].title"></p>
              <p class="sub" v-html="autoInd[4].sub_type[1].desc"></p>
            </div>
            <div class="autoIndivid-left__type__par">
              <p class="title" v-html="autoInd[4].sub_type[2].title"></p>
              <p class="sub" v-html="autoInd[4].sub_type[2].desc"></p>
            </div>
            <div class="autoIndivid-left__type__par">
              <p class="title" v-html="autoInd[4].sub_type[3].title"></p>
              <p class="sub" v-html="autoInd[4].sub_type[3].desc"></p>
            </div>
          </div>
        </div>
        <div class="autoIndivid-right">
          <img :src="cdn + autoInd[3].icon" alt="" />
        </div>
      </div>
      <div class="autoIndivid">
        <div class="autoIndivid-left">
          <p class="autoIndivid-left__title" v-html="autoInd[4].title"></p>
          <p class="autoIndivid-left__sub" v-html="autoInd[4].desc"></p>
          <div class="autoIndivid-left__type">
            <div class="autoIndivid-left__type__par">
              <p class="title" v-html="autoInd[4].sub_type[0].title"></p>
              <p class="sub" v-html="autoInd[4].sub_type[0].desc"></p>
            </div>
            <div class="autoIndivid-left__type__par">
              <p class="title" v-html="autoInd[4].sub_type[4].title"></p>
              <p class="sub" v-html="autoInd[4].sub_type[4].desc"></p>
            </div>
            <div class="autoIndivid-left__type__par">
              <p class="title" v-html="autoInd[4].sub_type[5].title"></p>
              <p class="sub" v-html="autoInd[4].sub_type[5].desc"></p>
            </div>
          </div>
        </div>
        <div class="autoIndivid-right">
          <img :src="cdn + autoInd[4].icon" alt="" />
        </div>
      </div>
    </div>
    <service-main />
  </div>
</template>

<script>
import HeadIndivid from "../components/HeadIndivid.vue";
import anotherSite from "../components/anotherSite.vue";
import ServiceMain from "../components/ServiceMain.vue";
import axios from "axios";
import { mapState } from "vuex";
export default {
  components: { HeadIndivid, anotherSite, ServiceMain },
  data() {
    return {
      autoInd: [],
      errors: [],
    };
  },
  created() {
    axios
      .get("V1/individual-insurance-auto")
      .then((response) => {
        this.autoInd = response.data.data;
      })
      .catch((e) => {
        this.errors.push(e);
      });
  },
  computed: {
    ...mapState(["cdn"]),
  },
};
</script>

<style lang="scss" scoped>
@mixin fontStyle($family, $style, $weight, $size, $lheight, $color) {
  font-family: $family;
  font-style: normal;
  font-weight: $weight;
  font-size: $size;
  line-height: $lheight;
  color: $color;
}
@mixin mobile {
  @media (min-width: 320px) and (max-width: 425px) {
    @content;
  }
}
@mixin tablet {
  @media (min-width: 425px) and (max-width: 769px) {
    @content;
  }
}
@mixin tablet-1024 {
  @media (min-width: 769px) and (max-width: 1023px) {
    @content;
  }
}
@mixin desktop {
  @media (min-width: 1023px) and (max-width: 1440px) {
    @content;
  }
}
@mixin desktop-max {
  @media (min-width: 1440px) and (max-width: 2560px) {
    @content;
  }
}
.link {
  color: #8948b1 !important;
}
.autoIndivid {
  background: #efefef;
  border-radius: 15px;
  display: flex;
  justify-content: space-between;
  margin-top: 85px;
  margin-bottom: 70px;
  padding: 65px 70px;
  align-items: center;
  &-left {
    &__title {
      @include fontStyle(Roboto, normal, 500, 38px, 43px, #373737);
      letter-spacing: 0.03em;
      text-transform: uppercase;
    }
    &__sub {
      @include fontStyle(Roboto, normal, 300, 18px, 22px, #373737);
    }
    &__type {
      margin-top: 40px;
      display: flex;
      justify-content: space-between;
      &__par {
        margin-right: 15px;
        .title {
          @include fontStyle(Roboto, normal, 400, 24px, 29px, #373737);
        }
        .sub {
          @include fontStyle(Roboto, normal, 300, 16px, 19px, #373737);
        }
      }
    }
    &__btn {
      &__mobile {
        display: none;
      }
      a {
        color: #8948b1 !important;
      }
      button {
        width: 280px;
        height: 69px;
        background-color: #fff;
        // background: linear-gradient(90deg, #FF4466 0%, #BE31CE 49.97%, #3641FF 100%);
        // border-radius: 15px;
        // border: none;
        // @include fontStyle(Roboto, normal, 600, 18px, 25px, #FFFFFF);
        // margin-top: 15px;
        // &:hover {
        // border: 3px solid #BE31CE;
        // }
        a {
          color: #8948b1 !important;
        }
        &:hover {
          a,
          span {
            text-decoration: underline;
          }
        }
      }
    }
  }
  &-right {
    img {
      width: 100%;
      min-width: 155px;
    }
  }
  &:hover {
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.35);
  }
  @include mobile() {
    margin: 30px 0;
    flex-wrap: wrap;
    border-radius: 3px;
    padding: 30px 20px;
    &-left {
      &__title {
        font-size: 16px;
        line-height: 17px;
        margin-bottom: 5px;
      }
      &__sub {
        font-size: 14px;
        line-height: 15px;
      }
      &__type {
        margin-top: 10px;
        flex-wrap: wrap;
        &__par {
          width: 100%;
          .title {
            font-size: 14px;
            line-height: 15px;
            font-weight: 600;
          }
          .sub {
            font-size: 14px;
            line-height: 15px;
            margin-top: -14px;
          }
        }
      }
      &__btn {
        display: none;
        &__mobile {
          display: block;
          text-align: center;
          margin: 0 auto;
          margin-top: 10px;
          // margin-bottom: -14%;
          order: 3;
          border-radius: 3px;
          button {
            background-color: #ffffff;
            min-width: 200px;
            height: 34px;
            border-radius: 3px;
            font-size: 12px;
            line-height: 14px;
            a {
              color: #8948b1;
            }
          }
        }
      }
    }
    &-right {
      text-align: center;
      margin: 0 auto;
      width: 100%;
      img {
        min-width: 122px;
        max-width: 214px;
        margin-top: -5px;
      }
    }
  }
  @include tablet() {
    margin: 30px 0;
    flex-wrap: wrap;
    border-radius: 3px;
    padding: 30px 20px;
    &-left {
      &__title {
        font-size: 16px;
        line-height: 17px;
        margin-bottom: 5px;
      }
      &__sub {
        font-size: 14px;
        line-height: 15px;
      }
      &__type {
        margin-top: 10px;
        flex-wrap: wrap;
        &__par {
          width: 100%;
          .title {
            font-size: 14px;
            line-height: 15px;
            font-weight: 600;
          }
          .sub {
            font-size: 14px;
            line-height: 15px;
            margin-top: -14px;
          }
        }
      }
      &__btn {
        display: none;
        &__mobile {
          display: block;
          text-align: center;
          margin: 0 auto;
          margin-top: 10px;
          // margin-bottom: -14%;
          order: 3;
          border-radius: 3px;
          button {
            background-color: #ffffff;
            min-width: 200px;
            height: 34px;
            border-radius: 3px;
            font-size: 12px;
            line-height: 14px;
            a {
              color: #8948b1;
            }
          }
        }
      }
    }
    &-right {
      text-align: center;
      margin: 0 auto;
      width: 100%;
      img {
        min-width: 122px;
        max-width: 214px;
        margin-top: -5px;
      }
    }
  }
  @include tablet-1024() {
    flex-wrap: wrap;
    &-left {
      &__title {
        font-size: 30px;
        line-height: 35px;
      }
      &__sub {
        padding-top: 20px;
        font-size: 12px;
        line-height: 16px;
      }
      &__btn {
        button {
          width: 240px;
          height: 50px;
          border-radius: 8px;
          font-size: 13px;
          line-height: 18px;
        }
      }
      &__type {
        .title {
          font-size: 15px;
          line-height: 22px;
          margin-bottom: 0;
        }
        .sub {
          font-size: 10px;
          line-height: 13px;
        }
      }
    }
    &-right {
      margin: 0 auto;
      img {
        min-width: 162px;
        width: 184px;
        margin-top: 30px;
      }
    }
  }
  @include desktop() {
    &-left {
      &__title {
        font-size: 28px;
        line-height: 33px;
        padding-bottom: 10px;
      }
      &__sub {
        font-size: 17px;
        line-height: 19px;
      }
      &__type {
        &__par {
          width: 200px;
          .title {
            font-size: 18px;
            line-height: 18px;
          }
          .sub {
            font-size: 13px;
            line-height: 14px;
          }
        }
      }
      &__btn {
        button {
          width: 200px;
          height: 50px;
          border-radius: 8px;
          font-size: 12px;
          line-height: 17px;
        }
      }
    }
    &-right {
      img {
        width: 242px;
      }
    }
  }
  @include desktop-max() {
    &-left {
      width: 75%;
      &__type {
        &__par {
          width: 200px;
        }
      }
    }
    // width: 100%;
    &-right {
      width: 24%;
    }
  }
}
</style>
