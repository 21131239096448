<template>
  <Footer>
    <div class="container animate__animated animate__fadeIn" id="footer" style="animation-duration: 8s;">
      <div class="foot">
        <div class="foot-logo">
          <a href="/"><img src="../assets/images/logo.svg" alt="" /></a>
        </div>
        <p class="foot-logo-par d_none">{{ $t("buttons.insurance") }}</p>
        <div class="foot-mid-mobile">
          <div class="foot-mid-mobile-1">
            <a href="/transport">{{ $t("nav.Bussiness") }}</a>
            <a href="/about">{{ $t("nav.AboutUs") }}</a>
          </div>
          <div class="foot-mid-mobile-2">
            <a href="/autoIndivid">{{ $t("nav.Individuals") }}</a>
            <a href="#">{{ $t("nav.Contacts") }}</a>
          </div>
          <div class="foot-mid-mobile-3">
            <a href="/policy">{{ $t("nav.ServicesandPayments") }}</a>
            <a href="/Address">{{ $t("nav.Address") }}</a>
          </div>
        </div>
        <div class="foot-mid">
          <div class="foot-mid__nav">
            <transition name="fade" mode="out-in">
              <a href="/transport">{{ $t("nav.Bussiness") }}</a>
            </transition>
            <transition name="fade" mode="out-in">
              <a href="/autoIndivid">{{ $t("nav.Individuals") }}</a>
            </transition>
            <transition name="fade" mode="out-in">
              <a href="/policy">{{ $t("nav.ServicesandPayments") }}</a>
            </transition>
            <transition name="fade" mode="out-in">
              <a href="/about">{{ $t("nav.AboutUs") }}</a>
            </transition>
            <span>
              <transition name="fade" mode="out-in">
                <a href="/Address">{{ $t("nav.Contacts") }}</a>
              </transition>
              <transition name="fade" mode="out-in">
                <a href="/Address">{{ $t("nav.Address") }}</a>
              </transition>
            </span>
          </div>
          <p class="foot-mid__par" v-html="foot1"></p>
        </div>
        <div class="foot-social">
          <div class="foot-social__nav">
            <a href="#">{{ $t("nav.Contacts") }}</a>
            <a href="/Address">{{ $t("nav.Address") }}</a>
          </div>
          <div class="foot-social__img">
            <a href="https://www.instagram.com/kupipolis.kz/" target="_blank"
              ><img src="../assets/images/ing.png" alt=""
            /></a>
            <a href="https://www.facebook.com/kupipolis.kz/" target="_blank"
              ><img src="../assets/images/fb.png" alt=""
            /></a>
            <a href="https://t.me/HelpCentras_bot" target="_blank"
              ><img src="@/assets/images/telegram.png" alt=""
            /></a>
            <a
              href="https://www.youtube.com/channel/UCf2GyNgEwFys5JdN4g6x4LQ/videos"
              target="_blank"
              ><img src="../assets/images/yt.png" alt=""
            /></a>
          </div>
        </div>
        <div class="social-mobile">
          <p class="foot-mid__par-1" v-html="foot1"></p>
          <div class="foot-social__img">
            <a href="https://www.instagram.com/kupipolis.kz/" target="_blank"
              ><img src="../assets/images/ing.png" alt=""
            /></a>
            <a href="https://www.facebook.com/kupipolis.kz/" target="_blank"
              ><img src="../assets/images/fb.png" alt=""
            /></a>
            <a href="https://t.me/HelpCentras_bot" target="_blank"
              ><img src="@/assets/images/telegram.png" alt=""
            /></a>
            <a
              href="https://www.youtube.com/channel/UCf2GyNgEwFys5JdN4g6x4LQ/videos"
              target="_blank"
              ><img src="../assets/images/yt.png" alt=""
            /></a>
          </div>
        </div>
      </div>
      <hr />
      <div class="cooperation">
        <div class="cooperation-left">
          <p class="m_none" v-html="foot2"></p>
          <p class="d_none" v-html="foot2"></p>
          <!-- <p class="d_none">© 2018—2022 ООО «Страховая компания «Манго». Лицензии на осуществление страхования СЛ № 4372 от 12.09.2019 г., СИ № 4372 от 12.09.2019 г. Выданы Банком России без ограничения срока действия</p> -->
          <p v-html="foot3"></p>
        </div>
        <div class="cooperation-right">
          <p class="cooperation-right__question" v-html="foot4"></p>
          <!-- <p class="cooperation-right__question">По всем вопросам пишите на <a href="mailto:support@mango.rocks">support@cic.kz</a>
                        </p> -->
          <p class="cooperation-right__offer" v-html="foot5"></p>
          <p class="cooperation-right__offer">
            {{ $t("par.dev1") }}
            <a href="https://studionomad.kz/">{{ $t("par.dev2") }}</a>
          </p>
          <p class="cooperation-right__offer">
            <a href="/П-08 Политика информационной безопасности.pdf">{{ $t("offer.security") }}</a>
          </p>
        </div>
      </div>
    </div>
  </Footer>
</template>

<script>
import axios from "axios";
import { mapState } from "vuex";
export default {
  data() {
    return {
      foot1: [],
      foot2: [],
      foot3: [],
      foot4: [],
      foot5: [],
    };
  },
  created() {
    axios
      .get("V1/footer")
      .then((response) => {
        this.foot1 = response.data.data[0].title;
        this.foot2 = response.data.data[1].title;
        this.foot3 = response.data.data[2].title;
        this.foot4 = response.data.data[3].title;
        this.foot5 = response.data.data[4].title;
      })
      .catch((e) => {
        this.errors.push(e);
      });
  },
  computed: {
    ...mapState(["cdn"]),
  },
};
</script>

<style lang="scss" scoped>
@mixin fontStyle($family, $style, $weight, $size, $lheight, $color) {
  font-family: $family;
  font-style: normal;
  font-weight: $weight;
  font-size: $size;
  line-height: $lheight;
  color: $color;
}
@mixin mobile {
  @media (min-width: 320px) and (max-width: 528px) {
    @content;
  }
}
@mixin tablet {
  @media (min-width: 529px) and (max-width: 968px) {
    @content;
  }
}
@mixin tablet-1024 {
  @media (min-width: 968px) and (max-width: 1024px) {
    @content;
  }
}
@mixin desktop {
  @media (min-width: 1024px) and (max-width: 1440px) {
    @content;
  }
}
@mixin desktop-max {
  @media (min-width: 1441px) {
    @content;
  }
}
.social-mobile {
  display: none;
  @include mobile() {
    display: flex;
    justify-content: space-between;
  }
  @include tablet() {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
}
.foot {
  margin-top: 64px;
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  // align-items: center;
  &-mid {
    &__nav {
      display: flex;
      justify-content: space-between;
      span {
        display: none;
      }
      a {
        text-decoration: none;
        @include fontStyle(Roboto, normal, 300, 16px, 22px, #373737);
        text-transform: uppercase;
        &:hover {
          font-weight: 400;
        }
      }
    }
    &-mobile {
      display: none;
    }
    &__par,
    &__par-1 {
      padding-top: 23px;
      @include fontStyle(Roboto, normal, 500, 20px, 24px, transparent);
      letter-spacing: 0.03em;
      text-transform: uppercase;
      background: linear-gradient(
        90deg,
        #ff143f 0%,
        #c600dd 49.97%,
        #1b27ff 100%
      );
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
    }
  }
  &-social {
    &__nav {
      display: flex;
      justify-content: space-between;
      // padding-top: 10px;
      a {
        @include fontStyle(Roboto, normal, 300, 16px, 19px, #373737);
        text-decoration: none;
        &:hover {
          font-weight: 400;
        }
      }
    }
    &__img {
      padding-top: 13px;
      a {
        padding-left: 5px;
        img {
          width: 39px;
          &:hover {
            box-shadow: 0 0 0 1px #f205ff;
            border-radius: 50%;
          }
        }
      }
    }
  }
  @media (max-width: 389px) {
    &-mid {
      &-mobile {
        width: 100%;
        &-1,
        &-2,
        &-3 {
          a {
            font-size: 10px !important;
          }
        }
        &-1 {
          width: 26% !important;
        }
        &-3 {
          width: 39% !important;
        }
      }
    }
  }
  @include mobile() {
    margin-top: 35px;
    margin-bottom: -7px;
    flex-wrap: wrap;
    &-logo {
      display: flex;
      justify-content: space-between;
      align-items: center;
      // max-width: 320px;
      width: 100%;
      img {
        width: 90px;
      }
      &-par {
        @include fontStyle(Roboto, normal, 300, 12px, 14px, #2e3236);
        padding-top: 17px;
        margin-bottom: 0 !important;
      }
    }
    &-mid {
      &__nav {
        display: none;
      }
      &__par-1 {
        font-size: 11px;
        line-height: 14px;
        width: 57%;
        text-transform: none;
      }
      &__par {
        display: none;
      }
      &-mobile {
        display: flex;
        padding-top: 18px;
        padding-bottom: 18px;
        width: 100%;
        // margin: 0 auto;
        &-1,
        &-2,
        &-3 {
          display: flex;
          flex-direction: column;
          // width: 33%;
          margin-right: 0;
          a {
            @include fontStyle(Roboto, normal, 500, 10px, 12px, #373737);
            padding-bottom: 7px;
          }
        }
        &-1 {
          width: 30%;
        }
        &-2 {
          width: 33%;
        }
        &-3 {
          width: 36%;
          a:first-child {
            font-size: 10px;
          }
        }
      }
    }
    &-social {
      display: none;
      &__img {
        display: flex;
        a {
          img {
            width: 25px;
          }
        }
      }
    }
  }
  @include tablet() {
    flex-wrap: wrap;
    &-logo {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 10px;
      // max-width: 420px;
      width: 100%;
      img {
        width: 110px;
      }
      &-par {
        @include fontStyle(Roboto, normal, 300, 12px, 14px, #2e3236);
        padding-bottom: 17px;
        margin-bottom: 0 !important;
      }
    }
    &-mid {
      &__nav {
        display: none;
      }
      &__par-1 {
        font-size: 10px;
        line-height: 12px;
      }
      &__par {
        display: none;
      }
      &-mobile {
        width: 100%;
        display: flex;
        &-1,
        &-2,
        &-3 {
          display: flex;
          flex-direction: column;
          a {
            @include fontStyle(Roboto, normal, 400, 11px, 14px, #373737);
          }
        }
        &-1,
        &-2 {
          width: 33%;
        }
        &-3 {
          width: 33%;
        }
      }
    }
    &-social {
      display: none;
      &__img {
        display: flex;
        a {
          img {
            width: 30px;
          }
        }
      }
    }
  }
  @include tablet-1024() {
    display: flex;
    flex-wrap: wrap;
    &-logo {
      margin-top: 0px;
      margin-right: 20px;
      img {
        width: 100px;
      }
    }
    &-mid {
      &__nav {
        a {
          font-size: 12px;
        }
      }
      &__par {
        font-size: 18px;
        line-height: 20px;
      }
    }
  }
  @media (min-width: 1025px) and (max-width: 1270px) {
    &-mid {
      &__nav {
        a {
          margin-right: 10px;
          font-size: 12px !important;
          line-height: 14px;
        }
      }
    }
    &-social {
      &__nav {
        a {
          margin-right: 10px;
          font-size: 12px;
          line-height: 14px;
        }
      }
    }
  }
  @include desktop() {
    &-mid {
      &__nav {
        a {
          font-size: 14px;
          line-height: 18px;
          margin-right: 10px;
        }
      }
      &__par {
        font-size: 18px;
      }
    }
    &-social {
      &__nav {
        a {
          margin-right: 10px;
        }
      }
    }
  }
  @include desktop-max() {
    &-logo {
      width: 17%;
    }
    &-mid {
      width: 53%;
      &__nav {
        a {
          margin-right: 10px;
        }
      }
    }
    &-social {
      &__img {
        text-align: end;
      }
      &__nav {
        a {
          margin-right: 10px;
        }
      }
    }
  }
}
.cooperation {
  display: flex;
  justify-content: space-between;
  margin-top: 35px;
  margin-bottom: 70px;
  &-left {
    margin-right: 20px;
    width: 74%;
    p {
      @include fontStyle(Roboto, normal, 300, 14px, 16px, #373737);
    }
  }
  &-right {
    p {
      @include fontStyle(Roboto, normal, 300, 14px, 17px, #373737);
      text-align: right;
      margin-bottom: 10px !important;
      &:first-child {
        margin-bottom: 10px;
      }
    }
  }
  @include mobile() {
    margin-bottom: 20px;
    margin-top: 20px;
    flex-wrap: wrap;
    &-left {
      width: 100%;
      p {
        font-size: 10px;
        line-height: 12px;
        color: #959595;
      }
    }
    &-right {
      p {
        font-size: 12px;
        line-height: 14px;
        font-weight: 400;
        text-align: left;
        &:first-child {
          margin-bottom: 2px;
        }
      }
    }
  }
  @include tablet() {
    margin-bottom: 0;
    flex-wrap: wrap;
    &-left {
      width: 100%;
      p {
        font-size: 10px;
        line-height: 12px;
        color: #959595;
      }
    }
    &-right {
      p {
        font-size: 12px;
        line-height: 14px;
        text-align: left;
      }
    }
  }
  @include desktop() {
    &-left {
      width: 70%;
    }
  }
}
</style>
